.team-preview-body {
    color: #003082;
}

.team-preview-title {
    width: 100%;
    height: 8rem;    
    font-size: 55px;
    font-weight: 600;
    padding: 0.7rem 0rem;
    background-color: #F8B700;
    border-bottom: 5px solid #0063D3;

}

.team-preview-subtitle {
    width: 100%;
    font-size: 30px;
    padding: 0.3rem 0rem;
    font-weight: 500;
}

.team-preview-grid {
    width: 80%;
    margin: auto;
    margin-top: 1em;
    padding-bottom: 20px;
    text-align: left;
    border-radius: 20px;
    color: black;
}

.team-preview-grid > div {
    display: inline-block;
}

.team-container {
    margin-top: 2em;
    margin-left: 1.5em;
    margin-right: 1.5em;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.team-preview-name-container {
    margin-top: auto;
    margin-bottom: auto;
    width: 10em;
    margin-left: 5px;
}

.team-preview-name-container > div {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    overflow-wrap: break-word;
}

.team-image-container {
    box-shadow: 0px 0px 10px #000000;
    background-color: white;
    position: relative;
    display: flex;
    margin: auto;
    justify-content: center;
    height: 80px;
    width: 80px;
}

.vehicle-main-svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 2;
}

.vehicle-highlight-svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 3  ;
}

.background-vehicle-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
}

.main-team-preview {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 5px solid #0063D3;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main-team-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 15px #F8B700;
    background-color: white;
    height: 200px;
    width: 200px;
}

.main-team-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    font-size: 40px;
    font-weight: 700;
}

@media only screen and (min-width: 768px) {
    .team-preview-grid {
        width: 90%;
    }

    .team-container {
        margin-top: 1.5em;
        margin-left: 1em;
        margin-right: 1em;
    }

    .team-image-container {
        width: 50px;
        height: 50px;
    }

    .team-preview-name-container {
        width: 5em;
    }

    .team-preview-name-container > div {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1024px) {
    .team-preview-grid {
        width: 95%;
    }

    .team-container {
        margin-top: 1.5em;
        margin-left: 1em;
        margin-right: 1em;
    }

    .team-image-container {
        width: 60px;
        height: 60px;
    }

    .team-preview-name-container {
        width: 6em;
    }

    .team-preview-name-container > div {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1440px) {
    .team-preview-grid {
        width: 85%;
    }

    .team-container {
        margin-top: 1.5em;
        margin-left: 1em;
        margin-right: 1em;
    }

    .team-image-container {
        width: 75px;
        height: 75px;
    }

    .team-preview-name-container {
        width: 8em;
    }

    .team-preview-name-container > div {
        font-size: 17px;
    }
}

@media only screen and (min-width: 2000px) {
    .team-preview-grid {
        width: 80%;
    }

    .team-container {
        margin-top: 2em;
        margin-left: 1.5em;
        margin-right: 1.5em;
    }

    .team-image-container {
        width: 85px;
        height: 85px;
    }

    .team-preview-name-container {
        width: 10em;
    }

    .team-preview-name-container > div {
        font-size: 20px;
    }
}

@media only screen and (max-width: 900px) {
    .team-preview-title {
        font-size: 45px;
    }
}

@media only screen and (max-height: 950px) {
    .team-image-container {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (max-height: 890px) {
    
    .team-preview-grid {
        margin-top: 0em;
    }

    .main-team-preview {
        margin-top: 0em;
    }
    
    .main-team-image-container {
        width: 140px;
        height: 140px;
        box-shadow: 0px 0px 5px #F8B700;
    }
}

@media only screen and (max-height: 800px) {

    .main-team-image-container {
        width: 120px;
        height: 120px;
        box-shadow: 0px 0px 5px #F8B700;
    }
}