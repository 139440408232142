@font-face {
    font-family: "Cherry Bomb";
    src: url("../../fonts/CherryBombOne-Regular.ttf");
}

.waiting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.waiting-title {
    font-family: Cherry Bomb, sans-serif;
    font-size: 50px;
    color: #003082;
    position: relative;
}

.waiting-title::after {
    content: "\2026";
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
    animation: dots steps(4, end) 2.5s infinite;
    width: 0px;
    position: absolute;
}
@keyframes dots {
    to {
        width: 45px;
    }
}

.boat-container {
    margin-top: 10em;
}

.train-container {
    left: 0;
    bottom: 80px;
    position: absolute;
}

.custom-loader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 8px solid;
    border-color: #003082 #0000;
    animation: s1 1s infinite;

    margin: 25px;
}
@keyframes s1 {
    to {
        transform: rotate(0.5turn);
    }
}

.waiting-title-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 12vh;
}

.back-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-btn.train {
    border-color: #003082;
    background-color: #0063D3;
    color: white;
}

.back-btn.train:hover {
    border-color: #003082;
    background-color: #004BA0;
    color: white;
}

.back-arrow {
    display: inline;
    margin: 0;
    font-size: 2em;
    line-height: 1;
    margin-bottom: 6px;
    margin-left: 2px;
}

.back-arrow.train {
    color: white;
}

.back-arrow.train:hover {
    color: white;
    transition-duration: 0.3s;
}

.tooltip-container {
    margin-top: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 10px;
    border-radius: 55px;
    z-index: 800;
}

.tooltip-skip {
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
}

.tooltip-skip:hover {
    opacity: 0.4;
}

.tooltip-text {
    font-size: 16px;
    font-family: Cherry Bomb, sans-serif;
    margin: 0;
}

.tooltip-right-arrow {
    width: 20px;
    height: 20px;
    color: #003082;
}

.tooltip-left-arrow {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    color: #003082;
}

@media only screen and (max-width: 1300px) {
    .tooltip-container {
        width: 90%;
    }
}

@media only screen and (max-width: 1080px) {
    .train-container {
        left: -200px;
    }
}

@media only screen and (max-width: 800px) {
    .train-container {
        left: -400px;
    }

    .waiting-title {
        font-size: 40px;
    }
}