@font-face {
    font-family: "Century Gothic";
    src: url("../../fonts/CenturyGothic.ttf");
}

.info-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 17rem;
    padding-left: 4rem;
    height: 100%;
    width: 50%;
    z-index: 12;
}

.info-modal {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
    z-index: 12;
    border: 1px solid black;
    width: 100%;
    height: fit-content;
    text-shadow: 1px 0px 0px black;
    font-family: "Century Gothic", sans-serif;
}

.info-modal-text1 {
    margin-top: 45px;
    font-size: 35px;
}

.info-modal-text2 {
    margin-top: 55px;
    font-size: 25px;
}

.info-modal-text3 {
    font-size: 25px;
}

.info-progress-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.correct-answer {
    background-color: rgb(82, 210, 82);
    color: white;
}

.incorrect-answer {
    background-color: rgb(255, 128, 0);
    color: white;
}

.correct-answer-display {
    font-size: 25px;
}

.exit-info-modal {
    color: rgb(255, 255, 0);
    font-size: 40px;
    position: absolute;
    right: 20px;
}

.exit-info-modal:hover {
    cursor: pointer;
}