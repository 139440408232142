.stepper {
    width: 50%;
    margin: auto;
    margin-top: 3em;
}

@media only screen and (max-width: 1080px) {
    .stepper {
        width: 80%;
    }
}