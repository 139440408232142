.background-train {
    position: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff6e4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: -3;
}

.sliding-background-clouds {
    background-image: url("../../../img/waiting-screen-background.png");
    background-size: auto 100vh;
    background-position: 0 0;

    width: 100%;
    height: 100%;
    position: absolute;
    animation: slide-clouds 30s linear infinite;
}

.background-train-track {
    background-image: url("../../../img/track-horizontal.png");
    background-size: auto 150px;
    background-repeat: repeat-x;
    background-position: 0 -20%;

    filter: blur(4px);

    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 50px;
    z-index: 0;
    animation: slide-bckground 5s linear infinite;
    animation-direction: reverse;
}

@keyframes slide-clouds {
    from {
        background-position: 0% 0;
    }

    to {
        background-position: 80% 0;
    }
}

@keyframes slide-bckground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 1500px 0;
    }
}
