@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-Light.ttf");
}

.round-modal-text-title {
    font-size: 25px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
    font-family: "Poppins";
}

.round-modal-text {
    font-family: "Poppins";
    font-size: 17px;
}
