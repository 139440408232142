.station-display {
    width: 50rem;
    background-color: #003082;
    color: white;
    border: 2px solid white;
}

.station-display > .header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 0.5em;
    font-size: 30px;
}

.train-info {
    text-align: left;
}

.current-points {
    text-align: right;
}

.station {
    text-align: left;
}

.active-station {
    text-align: left;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    color: #003082;
    background-color: white;
}

.station {
    padding-left: 1em;
    height: 50px;
    line-height: 50px;
    font-size: 17px;
    font-weight: 600;
}

.station > .station-info {
    height: 100%;
}

.next-text {
    font-size: 18px;
    font-weight: 600;
    padding-top: 0.3em;
    padding-bottom: 0;
}

.active-station > .station-info {
    padding-top: 0;
    font-size: 20px;
    font-weight: 700;
}

.btn {
    float: left;
}

.connector-container {
    height: 100%;
}

.hide-overflow {
    overflow: hidden;
}

.station-connector {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1300px) {
    
}