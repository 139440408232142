.end-leaderboard-container {
    padding-right: 20px;
    width: 80%;
    height: 40rem;
    margin: auto;
    font-size: 18px;
    color: white;
    text-align: left;
    overflow-y: auto;
}

.header-container {
    padding-right: 20px;
    width: 80%;
    margin: auto;
    margin-top: 2em;

    font-size: 18px;
    color: white;
    text-align: left;
}

.header-leaderboard-row {
    color: white;
    border-bottom: 2px solid white;
    margin-bottom: 1em;
    position: sticky;
}


.header-leaderboard-row > .row > div {
    margin-right: 10px;
}

.end-leaderboard-container > div {
    height: 2.5em;
    line-height: 2.5em;
}

.leaderboard-title {
    font-size: 70px;
    color: white;
}

.leaderboard-item {
    font-size: 25px;
    padding-left: 10px;
}

.leaderboard-item > .row > div {
    margin-right: 10px;
}

.leaderboard-background {
    background-color: #003082;
    width: 100%;
    height: 100vh;
    font-family: Cherry Bomb, sans-serif;
}

.main-team-item {
    background-color: white;
    color: #003082;
}

/* width */
.end-leaderboard-container::-webkit-scrollbar {
    width: 15px;
  }
  
  /* Track */
.end-leaderboard-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .end-leaderboard-container::-webkit-scrollbar-thumb {
    background: #F8B700; 
    border-radius: 10px;
  }
  
  .leaderboard-continue-button {
    color: white;
    width: 8em;
    height: 3em;
    line-height: 3em;
    border: 1px solid white;
    margin: auto;
    margin-top: 2em;

    background-color: #0063D3;
  }

  .leaderboard-continue-button:hover {
    cursor: pointer;
    background-color: #004BA0;
  }

  @media only screen and (max-width: 1150px) {
    .end-leaderboard-container {
        width: 100%;
    }

    .header-container {
        width: 100%;
    }
  }

  @media only screen and (max-width: 900px) {
    .leaderboard-item {
        font-size: 20px;
    }
  }

  @media only screen and (max-width: 790px) {
    .leaderboard-item {
        font-size: 18px;
    }
  }

  @media only screen and (max-height: 1000px) {
    .end-leaderboard-container {
        height: 35rem;
    }
  }

  @media only screen and (max-height: 1050px) {
    .end-leaderboard-container {
        height: 30rem;
    }
  }

  @media only screen and (max-height: 770px) {
    .end-leaderboard-container {
        height: 25rem;
    }

    .leaderboard-item {
        font-size: 20px;
    }
  }

  @media only screen and (max-height: 700px) {
    .end-leaderboard-container {
        height: 20rem;
    }

    .leaderboard-item {
        font-size: 18px;
    }
  }

  @media only screen and (max-height: 600px) {
    .end-leaderboard-container {
        height: 15rem;
    }
  }