.train-wheel-svg {
    animation: wheel 1s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
}

@keyframes wheel {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#cloud1 {
    animation: cloud1 1s ease-out infinite;
}

@keyframes cloud1 {
    0% {
        opacity: 0;
    }
    100% {
        transform: translate3d(55px, -63px, 0);
        opacity: 1;
    }
}

#cloud2 {
    animation: cloud2 1s ease-out infinite;
}

@keyframes cloud2 {
    100% {
        transform: translate3d(79px, -60px, 0);
    }
}

#cloud3 {
    animation: cloud3 1s linear infinite;
}

@keyframes cloud3 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translate3d(88px, -60px, 0);
    }
}

.train-svg {
    height: 180px;
    width: auto;
    filter: blur(4px);
}


@keyframes trainUpDown {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(2px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-2px);
    }

    100% {
        transform: translateY(0px);
    }
}