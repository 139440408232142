.available-rounds-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
}

.selected-rounds-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.selected-round-item {
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.round-duration-container {
    margin-left: 1rem;
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.dropbox-title {
    font-size: 15px;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.4rem;
    color: gray;
}

.empty-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    height: 6rem;
    opacity: 90%;
    margin-bottom: 0.7rem;
}

.empty-container-message {
    font-size: 17px;
    color: gray;
}

.dropbox-title-container {
    text-align: left;
    width: 100%;
}

.horizontal-line {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    height: 2px;
    background-color: gray;
}