.wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
        repeat-x;
    position: absolute;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    opacity: 0.2;
}

.waves > div:nth-child(1) {
    bottom: 85%;
    animation-delay: 1s;
    animation-duration: 5s;
}

.waves > div:nth-child(2) {
    top: 50px;
    animation-duration: 7s;
}

.waves > div:nth-child(3) {
    top: 200px;
    animation-delay: 0.5s;
    animation-duration: 5s;
}
.waves > div:nth-child(4) {
    top: 350px;
    animation-delay: 2s;
    animation-duration: 8s;
}
.waves > div:nth-child(5) {
    top: 500px;
    animation-delay: 0.1s;
    animation-duration: 7s;
}
.waves > div:nth-child(6) {
    top: 650px;
    animation-delay: 0.3s;
    animation-duration: 6s;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}