.ghost-vehicle {
    position: relative;
    offset-rotate: 0deg;
    border-style: solid;
    border-width: 3px;
}

.position-number {
    position: absolute;
    margin-left: 43px;
    background-color: rgba(255, 255, 255, 0.786);
    border-left: 2px solid;
    border-radius: 20%;
    top: 0;
    font-family: Cherry Bomb, sans-serif;
}

.closed-ghost {
    position: relative;
    offset-rotate: 0deg;
    height: 30px;
    width: 30px;
}

.ghost-vehicle-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}