.game-container {
    height: 100vh;
    padding-top: calc(15vh);
    display: flex;
    flex-direction: row;
}

.game-left-container {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.game-right-container {
    width: 50%;
}

@font-face {
    font-family: "Righteous";
    src: url("../../fonts/Righteous-Regular.ttf");
}

.question-header {
    height: 80px;
    top: 0;
    right: 0;
    left: 0;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.score {
    font-family: "Righteous", sans-serif;
    font-size: 35px;
    margin-right: 25px;
    position: absolute;
    right: 15px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.popup-background {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popup-text {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.race-minimap-container {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.race-status-container {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
}

.minimap-svg-path {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    z-index: 1;
}

.coloration-information-element {
    position: absolute;
    width: inherit;
    bottom: 20px;
}

@media only screen and (max-width: 1024px) {
    .game-left-container {
        width: 60%;
    }

    .game-right-container {
        width: 40%;
    }

    .minimap-svg-path {
        padding-right: 0;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media only screen and (min-width: 2000px) {
    .game-left-container {
        width: 60%;
    }

    .game-right-container {
        width: 40%;
    }
}

@media only screen and (max-height: 800px) {
    .game-container {
        padding-top: 10px;
    }
}