.position-text {
    font-size: 20px;
}

.team-name-text{
    font-size: 14px;
    text-align: left;
    margin-left: 10px;
    font-weight: 500;
    word-wrap: break-word;
}