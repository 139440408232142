@font-face {
    font-family: "Century Gothic";
    src: url("../../../fonts/CenturyGothic.ttf");
}

.login {
    margin-top: 7em;
}

.login-title {
    margin-top: 5em;
    color: #0c2340;
}

.login-title > p {
    font-size: 40px;
    font-weight: 600;
}

.login-input {
    width: 20em;
    font-size: 20px;
    height: 2em;

    margin-bottom: 0.2em;
    padding-left: 0.3em;

    color: #0c2340;
    border: 1px solid #0c2340;
}

.login-btn {
    width: 20em;
    height: 4em;

    margin-top: 2em;
    line-height: 4em;

    background-color: #ffffff;
    border: 2px solid #00a6d6;
    color: #00a6d6;
    transition-duration: 0.2s;
}

.login-btn:hover {
    background-color: #00a6d6;
    border: 2px solid #ffffff;
    color: #ffffff;
    transition-duration: 0.3s;
    cursor: pointer;
}

.login-btn > p {
    font-size: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pwd-message {
    color: red;
    font-size: 18px;
    margin-top: 15px;
    font-family: "Century Gothic";
}
