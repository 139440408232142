:root {
  --base-width: 2vw;
  --calculated-width: calc(var(--base-width) * (-11));
}

.flame-wrapper {
    display: inline-block;
    width: var(--base-width);
    height: var(--base-width);
    overflow: hidden;
    position: relative;
  }
  
  .flame-animation {
    width: var(--base-width);
    height: var(--base-width);
    background-image: url('../../../../img/flame_spritesheet.png');
    background-size: auto var(--base-width);
    background-repeat: no-repeat;
    animation: burn 0.7s steps(11) forwards infinite;
    transform-origin: center;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @keyframes burn {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: var(--calculated-width) 0;
    }
  }
  
  .flame-static {
    width: var(--base-width);
    height: var(--base-width);
    background-image: url('../../../../img/flame_static.png');
    background-size: var(--base-width) var(--base-width);
    background-repeat: no-repeat;
    transform-origin: center;
    position: absolute;
    top: 0;
    left: 0;
  }
  