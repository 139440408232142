@font-face {
    font-family: "Century Gothic";
    src: url("../../../fonts/CenturyGothic.ttf");
}

.true-false-question {
    margin-left: 4rem;
}

.flexbox-answers-mc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    width: 95%;
    margin-left: 1em;
}

.answer-mc {
    width: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
    font-size: 25px;
    font-family: "Century Gothic", sans-serif;
    cursor: pointer;
    padding: 15px;
    border: 1px solid #717171;
    background-color: white;
    border-radius: 5px;
}

.answer-mc:hover {
    transform: scale(1.05);
}
