.incomplete {
    color: #e03c31;
}

.complete {
    color: #0c2340;
}

.messages {
    font-size: 15px;
    font-weight: 500;
}

.start-game-container {
    margin-top: 0.5em;
    margin-left: 0.3em;
    font-size: 15px;
    text-align: left;
}

.start-game-btn {
    width: 15em;
    height: 3em;
    margin-top: 1em;
    background-color: #ffffff;
    border: 2px solid #00a6d6;
    color: #00a6d6;
    transition-duration: 0.2s;
}

.start-game-btn:hover {
    background-color: #00a6d6;
    border: 2px solid #ffffff;
    color: #ffffff;
    transition-duration: 0.3s;
    cursor: pointer;
}

.disabled {
    opacity: 50%;
}

.disabled:hover {
    cursor: default;
}