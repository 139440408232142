.team-preview-countdown-text {
    position: fixed;
    width: 30em;
    height: 15em;
    line-height: 15em;
    color: #003082;
    border: 3px solid black;
    background-color: #F8B700;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.team-preview-countdown-text > div {
    font-size: 90px;
    font-weight: 800;
}