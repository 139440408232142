.background {
    position: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

#b1 {
    fill: #c1ebeb;
}
