.race-map {
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.tree,
.lake,
.lake-one,
.lake-two,
.windmill,
.lilypad,
.swamp-tree,
.swamp-plant,
.swamp-rock,
.swamp-lake,
.crocodile {
    position: absolute;
}

@keyframes swell {
    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
}

@keyframes iceberg1 {
    0%,
    100% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(10px);
    }
}

@keyframes iceberg2 {
    0%,
    100% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(5px);
    }
}