@font-face {
    font-family: "Righteous";
    src: url("../../fonts/Righteous-Regular.ttf");
}

.question-container {
    margin-top: 2rem;
    height: 60rem;
    position: relative;
}

.question-header {
    height: 80px;
    top: 0;
    right: 0;
    left: 0;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.score {
    font-family: "Righteous", sans-serif;
    font-size: 35px;
    margin-right: 25px;
    position: absolute;
    right: 15px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.popup-background {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popup-text {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .question-container {
        margin-top: 1rem;
    }
}

@media only screen and (max-height: 1050px) {
    .question-container {
        height: 35rem;
    }
}