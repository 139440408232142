@font-face {
    font-family: "Kanit";
    src: url("../../../fonts/Kanit-Bold.ttf");
}

@font-face {
    font-family: "Century Gothic";
    src: url("../../../fonts/CenturyGothic.ttf");
}

.diff-modal-wrapper {
    position: absolute;
    padding-left: 4rem;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 10;
}

.diff-modal {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
    will-change: width, height;
    z-index: 10;
    overflow: hidden;
    border: 1px solid black;
    height: fit-content;
}

.diff-modal-text0 {
    margin-top: 35px;
    font-size: 45px;
    font-family: Kanit, Century Gothic, sans-serif;
    font-style: bold;
}

.diff-modal-text1 {
    margin: 25px 35px 25px 35px;
    font-size: 20px;
    font-family: Century Gothic, sans-serif;
}

.card-grid {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.card-container {
    display: flex;
    flex: 1;
    min-width: 0;
    justify-content: center;
    padding-bottom: 2rem;
}

.card-title {
    font-size: 30px;
    font-family: Century Gothic, sans-serif;
    font-style: bold;
    text-align: center;
}

.card-body {
    height: 10em;
}

.difficulty-card {
    cursor: pointer;
    flex: 1;
    margin: 15px;
    width: 10rem;
}

.difficulty-card:hover {
    transform: scale(1.05);
    background-color: rgb(239, 239, 239);
}

.emoji {
    font-size: 50px;
}

.card-points,
.card-attempts,
.card-streak {
    font-family: Century Gothic, sans-serif;
    font-style: bold;
    text-align: center;
    margin: 5px;
}

.optional-text-diff {
    font-family: Century Gothic, sans-serif;
    font-size: 15px;
}

@media only screen and (max-width: 1300px) {
    /* Adjust the width for smaller screens */
    .card-body {
        height: 10em;
    }

    .card-grid {
        margin-top: 0;
    }
    .emoji {
        margin-top: 25px;
        font-size: 45px;
    }
    .difficulty-card,
    .card {
        width: 10rem;
    }

    .diff-modal-wrapper {
        padding-left: 1rem;
    }
}

@media only screen and (max-width: 1090px) {
    .card-body {
        height: 8em;
    }

    .difficulty-card,
    .card {
        width: 8rem;
    }

    .emoji {
        margin-top: 5px;
        font-size: 45px;
    }

    .diff-modal-text0 {
        font-size: 30px;
    }

    .diff-modal-text1 {
        font-size: 15px;
    }
}