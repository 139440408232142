.line {
    width: 300px;
    height: 2px;
    background-color: black;
}

.svg-path {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}