.question-statistic {
    width: 80%;
    border: 2px solid #003082;
    border-radius: 10px;
    margin: 1em 0;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 15px;
    transition-duration: 0.3s;
}

.question-statistic:hover {
    background-color: #003082;
    color: white;
    border: 2px solid white;
    padding: 15px 20px;
    transition-duration: 0.3s;
    cursor: pointer;
}

.text {
    text-align: justify;
    font-weight: 500;
}

.stats {
    text-align: left;
    font-size: 15px;
}

.stats > * {
    margin-top: 5px;
    padding-left: 30%;
}

.accuracy {
    font-size: 22px;
    padding-bottom: 5px;
    padding-top: 10px;
}

.question-answer {
    padding-top: 15px;
    text-align: left;
    font-size: 17px;
}

.answer-title {
    font-weight: 600;
}

.statistics-wrapper {
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    color: black;
    border: 1px solid #003082;
    padding: 5px;
    width: 75%;
    position: absolute;
    top: 15%;
    left: 50%;
    min-height: 50%;
    transform: translate(-50%, 0%);
    padding-bottom: 20px;
}

.question-statistics-title {
    font-size: 70px;
    font-weight: 600;
    color: #003082;
}

.question-statistics-continue  {
    color: white;
    width: 8em;
    height: 3em;
    line-height: 3em;
    border: 1px solid white;
    position: absolute;
    right: 2em;
    top: 35px;
    background-color: #0063D3;
  }

  .question-statistics-continue:hover {
    cursor: pointer;
    background-color: #004BA0;
  }