@font-face {
    font-family: "Maven";
    src: url("../../../fonts/MavenPro-Variablet.ttf");
}
@font-face {
    font-family: "CMU Bright";
    src: url("../../../fonts/CMUBright.ttf");
}
@font-face {
    font-family: "Century Gothic";
    src: url("../../../fonts/CenturyGothic.ttf");
}

body {
    font-family: "Century Gothic", Arial, Helvetica, sans-serif;
}

.question-container-mc {
    margin-left: 4rem;
}

.question-container > * {
    opacity: 1;
}

.question-num {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-right: 10px;
    font-family: "Century Gothic", Arial, Helvetica, sans-serif;
    margin-top: 20px;
    opacity: 1;
    font-family: Maven, Century Gothic;
    border-radius: 20px;
    padding: 10px;
    width: 2.5em;
    opacity: 0.9;
}

.question-text-container {
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 30px;
}

.question-text {
    text-align: left;
    font-family: "CMU Bright", Helvetica, Arial, sans-serif;
    font-size: 25px;
    padding: 10px;
    width: 100%;
    overflow-wrap: break-word;
}

@media screen and (max-width: 1300px) {
    .question-text,
    .question-num,
    .answer-text {
        font-size: 15px;
    }
}

@media screen and (max-width: 900px) {
    .question-text,
    .question-num,
    .answer-text {
        font-size: 10px;
    }
}

.flexbox-answers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    margin-top: 20px;
    margin-left: 1em;
}

.answer {
    font-size: 18px;
    font-family: "Century Gothic", sans-serif;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    height: 60px;

    text-align: left;
    transition: all 0.2s ease-in-out;
    margin: 5px 0px 5px 0px;
    padding-left: 15px;

    color: black;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    align-self: center;
}

.answer-letter {
    padding: 15px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.answer-text {
    margin-left: 15px;
}

.answer:hover {
    transform: scale(1.05);
}

#answer1 {
    background-color: #00a6d6;
}

#answer2 {
    background-color: #26c63b;
}

#answer3 {
    background-color: #f7c500;
}

#answer4 {
    background: #ee6e37;
}

#answer5 {
    background-color: #ff00ff;
}

#answer6 {
    background-color: #ff8000;
}
